import React from 'react';

import SignUpButton from './SignUpButton';

interface ICtaProps {
  heading?: string;
  classes?: string;
  subheaderClass?: string;
  btnClasses?: string;
  gtagLabel?: string;
}

export default function Cta({ heading, classes, subheaderClass, btnClasses, gtagLabel }: ICtaProps){
  return (
    <div className={`cta ${classes ? classes : ''}`}>
      <h2 className={`cta__header ${subheaderClass ? subheaderClass : ''}`}>
        {heading ? 
          heading
          :
          'Start selling online in minutes'
        }
      </h2>
      <div className="row form--center">
        <SignUpButton classes={`${btnClasses ? btnClasses : 'btn-lg'}`} gtagLabel={gtagLabel} />
      </div>
    </div>
  );
}
