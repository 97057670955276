import React from "react";
import Link from 'next/link';

interface IPost {
  id: number;
  url: string;
  heroImgUrl: {
    large: string;
    small: string;
  }
  title: string;
  preview: string;
  dates: {
    updatedDate: string;
    publishedDate: string;
  }  
}

interface IPostList {
  posts: Array<IPost>;
  withoutDate?: boolean;
}

const PostList: React.FC<IPostList> = ({posts, withoutDate}) => {
  return (
    <ul className="blog-post-list list-unstyled row">
      {posts.map((post, index) => (
        <li className="blog-post-list__card col-sm-6 col-lg-3" key={index}>
          <Link href={`/blog${post.url}`}>
            <a className="blog-post-list__card-link">
              <article>
                <div className="blog-post-list__card-image-container">
                  <img className="blog-post-list__card-image img-fluid" src={post.heroImgUrl.small} alt={`Cover image for ${post.title}`}/>
                </div>
                <h3 className="blog-post-list__card-title">{post.title}</h3>
                <p className="blog-post-list__card-text">{post.preview}</p>
                {!withoutDate &&
                  <footer className="blog-post-list__card-details">
                    <p>{post.dates.updatedDate}</p>
                  </footer>
                }
              </article>
            </a>          
          </Link>
        </li>
      ))}
    </ul>
  )
}

export default PostList;