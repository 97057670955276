import Head from "next/head";
import React from "react";
import { useTranslation } from "react-i18next";
import { FORMPAY_CLIENT_BASE_URL } from "../../constants";
import { UrlSlugKeyWordEnum } from "../enums";

interface ILanderOgMetaDataProps {
  urlSlug: string;
  enhancedTitle?: string;
  enhancedDescription?: string;
  enhancedImageUrl?: string;
}

const LanderOgMetaData: React.FC<ILanderOgMetaDataProps> = ({ urlSlug, enhancedTitle, enhancedDescription, enhancedImageUrl }) => {
  const { t } = useTranslation();

  const verticalMarketName =
    UrlSlugKeyWordEnum[urlSlug as keyof typeof UrlSlugKeyWordEnum];
  const formUrl = FORMPAY_CLIENT_BASE_URL + urlSlug;
  const getEnhancedImageUrl = () => {
    switch (true) {
      case (!!enhancedImageUrl):
        return enhancedImageUrl;
      default:
        return "https://imagedelivery.net/INhRwDjwvBqjuxH-dRz9eQ/c0090095-72b4-4dd3-2724-99f73dad0800/public";
    }
  };
  const getEnhancedDescription = () => {
    switch (true) {
      case (!!enhancedDescription):
        return enhancedDescription;
      default:
        return "Create a beautiful online store with payments in minutes.";
    }
  };
  const getEnhancedTitle = (slug: string) => {
    const addDivider = " | ";
    switch (true) {
      case slug === "/login":
        return t("Login") + addDivider;
      case slug === "/dashboard":
        return t("Order Dashboard") + addDivider;
      case slug === "/blog":
        return t("Blog") + addDivider;
      case (!!enhancedTitle && urlSlug.slice(0, 5) === "/blog"):
        return enhancedTitle;
      case (!!enhancedTitle && !urlSlug.includes("/blog")):
        return enhancedTitle + addDivider;
      default:
        return "";
    }
  };

  const browserTitle = (!urlSlug.includes("/blog") || urlSlug === "/blog") ?
    `${getEnhancedTitle(
      urlSlug
    )}FormPay - The simplest way to sell ${
      verticalMarketName ? verticalMarketName + " " : ""
    }online`
    :
    `${getEnhancedTitle(urlSlug)}`
  ;

  return (
    <Head>
      <title>{browserTitle}</title>
      <meta property="og:url" content={formUrl} />
      <meta property="og:image" content={getEnhancedImageUrl()} />
      <meta
        property="og:title"
        content={browserTitle}

        // TODO: once SSR/SSG is fixed, add this back
        // content={t(`theSimplestWayToSell`, {
        //   enhancedTitle: getEnhancedTitle(urlSlug),
        //   verticalMarketName: verticalMarketName
        //     ? verticalMarketName + " "
        //     : "",
        // })}
      />
      <meta
        property="og:description"
        content={getEnhancedDescription()}
      />
    </Head>
  );
};

export default LanderOgMetaData;
